import {MessageProps} from '@sabre/spark-react-core/message/message.types';
import {Button, Message} from '@sabre/spark-react-core';
import styles from './NotificationMessage.module.css';
import {cssClasses} from '../../../../common/helpers/browser/cssClasses';
import {useControllers} from '../../../../common/helpers/react/hooks/useControllers';
import {useRef} from 'react';
import {useAsyncEffect} from '../../../../common/helpers/react/hooks/useAsyncEffect';
import {useI18Next} from '../../../../common/helpers/react/hooks/useI18Next';

export type NotificationMessageProps = Omit<MessageProps, 'ariaLabelledBy' | 'ariaLabel'> & {
    id: string,
    zIndex?: number,
    fade?: number
}

export default function NotificationMessage(props: NotificationMessageProps) {
    const {t} = useI18Next();
    const dialogController = useControllers().dialog;

    const ref = useRef<HTMLDivElement>(null);
    const fade = props.fade ?? 1;

    useAsyncEffect(() => {
        if (ref.current) {
            ref.current.style.filter = `grayscale(${1 - fade}) saturate(${fade})`;
        }
    });

    return <div
        ref={ref}
        className={cssClasses(
            styles.Envelope,
            styles[`Envelope-${props.status}`],
            `qa-notification-message`
        )}
        style={{
            zIndex: props.zIndex ?? 99999,
            'filter': `grayscale(0) saturate(1.2)`,
            transition: 'filter 1s'
        }}
    >
        <Message
            ariaLabel={`${props.title ?? ''} ${props.content ?? ''}`.trim()}
            {...props}
            className={cssClasses(
                styles.Message,
                props.className,
                props.title ? undefined : styles.MessageWithNoTitle
            )}
        />
        <Button
            className={cssClasses(
                styles.Button,
                'spark-message__heading',
                'aat-autokey-escape-first',
                props.animate ? styles.ButtonNew : null,
                props.title ? undefined : styles.ButtonWithNoTitle
            )}
            textOnly={true}
            onClick={() => dialogController.closeNotification(props.id)}
        >{t('DISMISS')}</Button>
    </div>;
}
