import {Icon} from '@sabre/spark-react-core';
import React from 'react';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import DefaultTable, {DefaultTableCheckboxModeProps} from '../../../common/components/table/DefaultTable';
import {ColumnDescription} from '../../../common/components/table/ColumnDescription';
import {PartialRole} from '../../../../../core/rest-client/aat';
import {tAsString} from '../../../../../common/helpers/react/text/tAsString';
import {asNumber} from '../../../../../common/helpers/converters/asNumber';

export type RolesTableProps = Partial<DefaultTableCheckboxModeProps> & {
    addAggregatedRolesMode?: boolean;
    removeRolesMode?: boolean;
}

export default function RolesTable (props: RolesTableProps) {
    const roles = useControllers().roles;
    let data;
    if (props.addAggregatedRolesMode)
    {
        data = roles.getSearchedRolesToAggregate$().asState() ?? [];
        const nestedRoles = roles.getNestedRoles$().asState() ?? [];
        if (nestedRoles.length > 0)
        {
            data = data
                .filter(it => !nestedRoles.find(nested => nested.id === it.id))
                .filter(it => !nestedRoles[nestedRoles.length - 1]?.parentRoles.find(parent => parent.id === it.id));
        }
    } else
    {
        data = roles.getVisibleRoles$().asState() ?? [];
    }

    const isBusy = roles.isBusy$().asState() !== false && !props.removeRolesMode;
    const columns: ColumnDescription<PartialRole>[] = [
        {
            name: 'name',
            header: tAsString('ROLE_NAME'),
            size: 5
        },
        {
            name: 'id',
            header: tAsString('ROLE_ID'),
            size: 7
        },
        {
            size: 1,
            align: 'right',
            ariaLabel: tAsString('ROLE_LINK'),
            render: (it: PartialRole) => <a
                aria-label={tAsString('SHOW_X_ROW_DETAILS', {
                    name: it.name
                })}
                href={document.location.hash}
            >
                <Icon name={'arrow-chevron-right'}/>
            </a>,
            hideInCheckboxMode: true
        }
    ];

    const onClick = async (id: string) => {
        if (!isBusy) {
            await roles.showParentRolesForGivenRole(asNumber(id), true);
        }
    }

    return <DefaultTable
        columns={columns}
        data={data}
        isBusy={isBusy}
        {...(props.addAggregatedRolesMode || props.removeRolesMode ? {
            checkedRows: props.checkedRows,
            setCheckedRows: props.setCheckedRows
        } : {
            onClick
        })}
    />;
}