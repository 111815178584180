import React, {useEffect, useState} from 'react';
import {tAsString} from '../../../../../common/helpers/react/text/tAsString';
import {RolesToolbar} from './RolesToolbar';
import RolesTable from './RolesTable';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import LoadingProgress from '../../../common/placeholders/loading/LoadingProgress';

export default function RolesSearchPresenter() {
    const [checkedRows, setCheckedRows] = useState<string[]>([]);
    const [removeRolesMode, setRemoveRolesMode] = useState(false);
    const roles = useControllers().roles;
    const isBusy = ['GETTING_PARENT_ROLES', 'UPDATING_PARENT_ROLES'].includes(roles.isBusy$().asState() as string);

    useEffect(() => {
        setCheckedRows([]);
    }, [removeRolesMode]);

    return (<>
        <h1>{tAsString('MANAGE_ROLES')}</h1>
        {isBusy && removeRolesMode && <LoadingProgress/>}
        <RolesToolbar removeRolesMode={removeRolesMode} setRemoveRolesMode={setRemoveRolesMode} checkedRows={checkedRows}/>
        <RolesTable checkedRows={checkedRows} setCheckedRows={setCheckedRows} removeRolesMode={removeRolesMode}/>
    </>);
}