/*** Autogenerated. Use 'npm run generate-sources' to regenerate. ***/    
import {JSONSchema4} from 'json-schema';

export const collections: Record<string, { schema: JSONSchema4 }> = {
    agencies: {schema: {"title":"RxAgency","primaryKey":"id","required":["id","name"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"name":{"type":"string","description":""},"parent_agency":{"type":"string","ref":"agencies","description":"Foreign key, reference to `agencies` collection."},"is_international":{"type":"boolean","default":false,"description":""},"location":{"ref":"locations","type":"string","description":"Foreign key, reference to `locations` collection."},"total_children":{"type":"number","description":""},"active__document":{"type":"boolean","default":false,"description":"Special property. Property indicates if given document is active and should be used in application. Document with this property set to `false` should be considered as non-existing."}},"additionalProperties":false,"description":"Collection `agencies` contains documents of `RxAgency` type."}},
    agents: {schema: {"title":"RxAgent","primaryKey":"id","required":["id","sabre_id","epr","name","pcc"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"sabre_id":{"type":"string","description":""},"epr":{"type":"string","description":""},"name":{"type":"string","description":""},"pcc":{"ref":"pccs","type":"string","description":"Foreign key, reference to `pccs` collection."},"active__document":{"type":"boolean","default":false,"description":"Special property. Property indicates if given document is active and should be used in application. Document with this property set to `false` should be considered as non-existing."}},"additionalProperties":false,"description":"Collection `agents` contains documents of `RxAgent` type."}},
    configurations: {schema: {"title":"RxConfiguration","primaryKey":"id","required":["id","redapp_name","configuration_name","value","description"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"redapp_name":{"type":"string","description":""},"configuration_name":{"type":"string","description":""},"value":{"type":"string","description":""},"description":{"type":"string","description":""},"active__document":{"type":"boolean","default":false,"description":"Special property. Property indicates if given document is active and should be used in application. Document with this property set to `false` should be considered as non-existing."}},"additionalProperties":false,"description":"Collection `configurations` contains documents of `RxConfiguration` type."}},
    group_agencies: {schema: {"title":"RxGroupAgency","primaryKey":"id","required":["id","group","agency"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"group":{"ref":"groups","type":"string","description":"Foreign key, reference to `groups` collection."},"agency":{"ref":"agencies","type":"string","description":"Foreign key, reference to `agencies` collection."},"active__document":{"type":"boolean","default":false,"description":"Special property. Property indicates if given document is active and should be used in application. Document with this property set to `false` should be considered as non-existing."}},"additionalProperties":false,"description":"Collection `group_agencies` contains documents of `RxGroupAgency` type."}},
    group_agents: {schema: {"title":"RxGroupAgent","primaryKey":"id","required":["id","group","agent"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"group":{"ref":"groups","type":"string","description":"Foreign key, reference to `groups` collection."},"agent":{"ref":"agents","type":"string","description":"Foreign key, reference to `agents` collection."},"active__document":{"type":"boolean","default":false,"description":"Special property. Property indicates if given document is active and should be used in application. Document with this property set to `false` should be considered as non-existing."}},"additionalProperties":false,"description":"Collection `group_agents` contains documents of `RxGroupAgent` type."}},
    group_pccs: {schema: {"title":"RxGroupPcc","primaryKey":"id","required":["id","group","pcc"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"group":{"ref":"groups","type":"string","description":"Foreign key, reference to `groups` collection."},"pcc":{"ref":"pccs","type":"string","description":"Foreign key, reference to `pccs` collection."},"active__document":{"type":"boolean","default":false,"description":"Special property. Property indicates if given document is active and should be used in application. Document with this property set to `false` should be considered as non-existing."}},"additionalProperties":false,"description":"Collection `group_pccs` contains documents of `RxGroupPcc` type."}},
    group_products: {schema: {"title":"RxGroupProduct","primaryKey":"id","required":["id","group","product"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"group":{"ref":"groups","type":"string","description":"Foreign key, reference to `groups` collection."},"product":{"ref":"products","type":"string","description":"Foreign key, reference to `products` collection."},"product_enablement":{"type":"string","default":"DEFAULT","description":""},"redapp_placement":{"type":"string","default":"apps-panel","description":""},"redAppConfiguration":{"type":"string","description":""},"active__document":{"type":"boolean","default":false,"description":"Special property. Property indicates if given document is active and should be used in application. Document with this property set to `false` should be considered as non-existing."}},"additionalProperties":false,"description":"Collection `group_products` contains documents of `RxGroupProduct` type."}},
    group_properties: {schema: {"title":"RxGroupProperty","primaryKey":"id","required":["id","group","property"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"group":{"ref":"groups","type":"string","description":"Foreign key, reference to `groups` collection."},"property":{"ref":"properties","type":"string","description":"Foreign key, reference to `properties` collection."},"override_value":{"type":"string","description":""},"possible_values":{"type":"object","additionalProperties":{"type":"string"},"description":""},"allow_user_override":{"type":"boolean","description":""},"active__document":{"type":"boolean","default":false,"description":"Special property. Property indicates if given document is active and should be used in application. Document with this property set to `false` should be considered as non-existing."}},"additionalProperties":false,"description":"Collection `group_properties` contains documents of `RxGroupProperty` type."}},
    groups: {schema: {"title":"RxGroup","primaryKey":"id","required":["id","name"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"name":{"type":"string","description":""},"description":{"type":"string","description":""},"total_agents":{"type":"integer","default":0,"description":""},"editor_name":{"type":"string","description":""},"active__document":{"type":"boolean","default":false,"description":"Special property. Property indicates if given document is active and should be used in application. Document with this property set to `false` should be considered as non-existing."}},"additionalProperties":false,"description":"Collection `groups` contains documents of `RxGroup` type."}},
    links: {schema: {"title":"RxLink","primaryKey":"name","required":["name","url"],"version":0,"type":"object","properties":{"name":{"maxLength":10000,"type":"string","description":"Primary key."},"url":{"type":"string","description":""}},"additionalProperties":false,"description":"Collection `links` contains documents of `RxLink` type."}},
    locations: {schema: {"title":"RxLocation","primaryKey":"id","required":["id"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"city":{"type":"string","description":""},"country":{"type":"string","description":""},"country_code":{"type":"string","maxLength":2,"description":""},"state":{"type":"string","description":""},"street":{"type":"string","description":""}},"additionalProperties":false,"description":"Collection `locations` contains documents of `RxLocation` type."}},
    menu_items: {schema: {"title":"RxMenuItem","primaryKey":"label","required":["label","link"],"version":0,"type":"object","properties":{"label":{"maxLength":10000,"type":"string","description":"Primary key."},"link":{"ref":"links","type":"string","description":"Foreign key, reference to `links` collection."},"order":{"type":"integer","default":0,"description":""}},"additionalProperties":false,"description":"Collection `menu_items` contains documents of `RxMenuItem` type."}},
    misc_data: {schema: {"title":"RxMiscData","primaryKey":"id","required":["id","data"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"data":{"type":"object","description":""}},"additionalProperties":false,"description":"Collection `misc_data` contains documents of `RxMiscData` type."}},
    notifications: {schema: {"title":"RxNotification","primaryKey":"id","required":["id","ts","content"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"ts":{"type":"number","default":0,"description":""},"read":{"type":"boolean","default":false,"description":""},"title":{"type":"string","default":"","description":""},"content":{"type":"string","description":""},"status":{"type":"string","default":"info","description":""},"stick_to":{"type":"string","default":"","description":""}},"additionalProperties":false,"description":"Collection `notifications` contains documents of `RxNotification` type."}},
    pccs: {schema: {"title":"RxPcc","primaryKey":"id","required":["id","code","agency_subscriber_name"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"code":{"type":"string","maxLength":4,"description":""},"agency_subscriber_name":{"type":"string","description":""},"agency":{"ref":"agencies","type":"string","description":"Foreign key, reference to `agencies` collection."},"location":{"ref":"locations","type":"string","description":"Foreign key, reference to `locations` collection."},"total_agents":{"type":"number","description":""},"active__document":{"type":"boolean","default":false,"description":"Special property. Property indicates if given document is active and should be used in application. Document with this property set to `false` should be considered as non-existing."}},"additionalProperties":false,"description":"Collection `pccs` contains documents of `RxPcc` type."}},
    product_agencies: {schema: {"title":"RxProductAgency","primaryKey":"id","required":["id","groupId","product","agency"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"groupId":{"type":"string","maxLength":100,"description":""},"product":{"ref":"products","type":"string","description":"Foreign key, reference to `products` collection."},"agency":{"ref":"agencies","type":"string","description":"Foreign key, reference to `agencies` collection."},"active__document":{"type":"boolean","default":false,"description":"Special property. Property indicates if given document is active and should be used in application. Document with this property set to `false` should be considered as non-existing."}},"additionalProperties":false,"description":"Collection `product_agencies` contains documents of `RxProductAgency` type."}},
    product_agents: {schema: {"title":"RxProductAgent","primaryKey":"id","required":["id","groupId","product","agent"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"groupId":{"type":"string","maxLength":100,"description":""},"product":{"ref":"products","type":"string","description":"Foreign key, reference to `products` collection."},"agent":{"ref":"agents","type":"string","description":"Foreign key, reference to `agents` collection."},"active__document":{"type":"boolean","default":false,"description":"Special property. Property indicates if given document is active and should be used in application. Document with this property set to `false` should be considered as non-existing."}},"additionalProperties":false,"description":"Collection `product_agents` contains documents of `RxProductAgent` type."}},
    product_pccs: {schema: {"title":"RxProductPcc","primaryKey":"id","required":["id","groupId","product","pcc"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"groupId":{"type":"string","maxLength":100,"description":""},"product":{"ref":"products","type":"string","description":"Foreign key, reference to `products` collection."},"pcc":{"ref":"pccs","type":"string","description":"Foreign key, reference to `pccs` collection."},"active__document":{"type":"boolean","default":false,"description":"Special property. Property indicates if given document is active and should be used in application. Document with this property set to `false` should be considered as non-existing."}},"additionalProperties":false,"description":"Collection `product_pccs` contains documents of `RxProductPcc` type."}},
    products: {schema: {"title":"RxProduct","primaryKey":"id","required":["id","name"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"name":{"type":"string","description":""},"description":{"type":"string","default":"","description":""},"type":{"type":"string","default":"FIXED","description":""},"total_agents":{"type":"number","description":""},"using_agents":{"type":"number","description":""},"active__document":{"type":"boolean","default":false,"description":"Special property. Property indicates if given document is active and should be used in application. Document with this property set to `false` should be considered as non-existing."}},"additionalProperties":false,"description":"Collection `products` contains documents of `RxProduct` type."}},
    properties: {schema: {"title":"RxProperty","primaryKey":"id","required":["id"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"name":{"type":"string","description":""},"description":{"type":"string","default":"","description":""},"active__document":{"type":"boolean","default":false,"description":"Special property. Property indicates if given document is active and should be used in application. Document with this property set to `false` should be considered as non-existing."}},"additionalProperties":false,"description":"Collection `properties` contains documents of `RxProperty` type."}},
    redApps: {schema: {"title":"RxRedapp","primaryKey":"iu_name","required":["name","iu_name"],"version":0,"type":"object","properties":{"name":{"type":"string","description":""},"iu_name":{"maxLength":10000,"type":"string","description":"Primary key."}},"additionalProperties":false,"description":"Collection `redApps` contains documents of `RxRedapp` type."}},
    roles: {schema: {"title":"RxRole","primaryKey":"id","required":["id","name"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"name":{"type":"string","description":""}},"additionalProperties":false,"description":"Collection `roles` contains documents of `RxRole` type."}},
    settings: {schema: {"title":"RxSetting","primaryKey":"id","required":["id"],"version":0,"type":"object","properties":{"id":{"maxLength":10000,"type":"string","description":"Primary key."},"order":{"type":"integer","default":0,"description":""},"title":{"type":"string","description":""},"subtitle":{"type":"string","description":""},"default_page":{"type":"string","description":""},"current_page":{"type":"string","description":""},"lang":{"type":"string","description":""},"env":{"type":"string","description":""},"cookie_atk":{"type":"string","description":""},"cookie_wam":{"type":"string","description":""},"url_slp":{"type":"string","description":""},"url_aat":{"type":"string","description":""},"logging_level":{"type":"string","description":""},"dev_mode":{"type":"boolean","description":""},"use_search_params":{"type":"boolean","description":""}},"additionalProperties":false,"description":"Collection `settings` contains documents of `RxSetting` type."}}
};
