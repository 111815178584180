import {ModalConfig} from '../../../../controllers/dialog/ModalConfig';
import styles from './MaximizedModal.module.css';
import {useControllers} from '../../../../common/helpers/react/hooks/useControllers';
import ActionPanel from '../../common/components/actionPanel/ActionPanel';
import {useLayoutEffect} from 'react';
import {Button} from '@sabre/spark-react-core';
import {ModalButtons} from '../../../../controllers/dialog/ModalButtons';
import {tAsString} from '../../../../common/helpers/react/text/tAsString';
import {cssClasses} from '../../../../common/helpers/browser/cssClasses';
import FocusTrap from 'focus-trap-react';
import CANCEL = ModalButtons.CANCEL;

export default function MaximizedModal(props: ModalConfig) {
    const dialogController = useControllers().dialog;
    const cancelConfig = CANCEL(dialogController);

    const modalProps = {...props};
    delete modalProps.buttons;
    delete modalProps.maximized;

    useLayoutEffect(() => {
        document.querySelector<HTMLElement>(`.${styles.ActionPanel} button`)?.focus();
    });

    return (
        <FocusTrap focusTrapOptions={{allowOutsideClick: true}}>
            <div className={cssClasses(
                'aat-maximized-modal',
                styles.Modal
            )}>
                <ActionPanel
                    className={styles.ActionPanelTop}
                    position={'top'}
                    element={
                        <div>
                            <header className={cssClasses(
                                'spark-pull-left',
                                'aat-modal-title'
                            )}>{props.title}</header>
                            <div className={'spark-pull-right'}>
                                <Button
                                    icon={'spark-icon-close'}
                                    tooltip={``}
                                    ariaLabel={tAsString('CLOSE')}
                                    className={cssClasses(
                                        styles.ModalXButton,
                                        'qa-modal-x-button'
                                    )}
                                    onClick={cancelConfig.onClick}
                                />
                            </div>
                        </div>
                    }
                />
                <div className={styles.Content}>{props.children}</div>
                <ActionPanel
                    className={styles.ActionPanelBottom}
                    buttons={props.buttons}
                />
            </div>
        </FocusTrap>
    );
}
