import {Service} from '../Service';
import {upsertInto} from '../../../common/helpers/db/upsertInto';
import {asRxRoleArray} from '../../../common/model/db/types/RxRole';
import {UpsertMode} from '../../../common/helpers/db/UpsertMode';
import {PartialParentRole, PartialRole, Role} from '../../rest-client/aat';

export class RolesService extends Service {
    async searchRolesByName(pattern: string): Promise<Array<PartialRole>> {
        const rolesApi = await this.services.aat.Roles;

        const response = await rolesApi.searchRoles({
            searchRoleRequest: {
                pattern: pattern
            }
        });

        this.checkStatus(response);

        await this.updateDatabase(response.roles);

        return response.roles;
    }

    async searchRoleById(id: number): Promise<Role | undefined> {
        const rolesApi = await this.services.aat.Roles;
        try
        {
            const response = await rolesApi.getRole({id: id});

            this.checkStatus(response);
            await this.updateDatabase([response.role]);

            return response.role;
        } catch (error)
        {
            return undefined;
        }
    }

    async createRole(roleName: string): Promise<Role> {
        const rolesApi = await this.services.aat.Roles;
        const roleResponse = await rolesApi.createRole({
            roleRequest: {
                name: roleName
            }
        });

        this.checkStatus(roleResponse);

        return roleResponse.role;
    }

    async updateParentRoles(id: number, parentRoles: PartialParentRole[]): Promise<Role> {
        const rolesApi = await this.services.aat.Roles;
        const roleResponse = await rolesApi.updateRole({
            id,
            updateRoleRequest: {
                parentRoles
            }
        });

        this.checkStatus(roleResponse);

        return roleResponse.role;
    }

    private async updateDatabase(roles: (PartialRole | undefined)[]): Promise<void> {
        await upsertInto(
            this.db.roles,
            asRxRoleArray(roles),
            UpsertMode.ADD_OVERWRITE
        );
    }
}